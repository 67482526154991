<template>
	<div>
		<a-crm-container>
			<template>
				<div class='a-crm-content'>
					<div class='a-crm-content-box'>
						<div>
							<div class='a-crm-content-group'>
								<template v-if='content'>
									<ul class='a-crm-list' style='padding-left: 0; margin-bottom: 20px'>
										<li class='a-crm-list-item child'>
											<span class='a-crm-list-link' @click='tree = !tree'>{{$t("crm.base_tree")}}</span>
										</li>
									</ul>
								</template>
								<p class='a-sub-heading a-crm-content-title' v-else>{{$t("crm.my_base")}}</p>

								<v-text-field
									:placeholder='$t("crm.fast_search")'
									icon-prepend='search'
									name='search'
									v-model='search'
									v-if='tree'
								/>
							</div>
						</div>

						<ul class='a-crm-list' v-show='tree'>
							<li class='a-crm-list-item' :class="{'child': item.child}"
								v-for='(item, i) in items'
								:key='i'
							>
								<template v-if='item.child'>
									<v-accordion hideTriggerIcon>
										<template v-slot:title>
											<v-icon :icon='i === Accordion.active ? "minus" : "plus"' :size='8' class='green-persian--text' />
											{{ item.title }}
										</template>

										<template v-slot:content>
											<ul class='a-crm-list'>
												<li class='a-crm-list-item'
													v-for='(child, i) in item.child'
													:key='i'
												>
													<span class='a-crm-list-link' :class='{"active": content && child.title === content.title}' @click='!content ? tree = false : false, content = child'>
														{{child.title}}
													</span>
												</li>
											</ul>
										</template>
									</v-accordion>
								</template>
								<template v-else>
									<span class='a-crm-list-link' :class='{"active": content && item.title === content.title}' @click='!content ? tree = false : false, content = item'>
										{{item.title}}
									</span>
								</template>
							</li>
						</ul>

						<div class='a-crm-content-info' v-if='content'>
							<p class='a-sub-heading'>{{content.title}}</p>
							<div class='a-content-block' v-for='(item, i) in content.content' :key='i'>
								<p v-for='(item, i) in item.block' :key='i' v-html='item'></p>
							</div>
						</div>
					</div>
				</div>
			</template>
		</a-crm-container>
	</div>
</template>

<script>
	import ACrmContainer from '@/views/crm/container';

	export default {
		provide() {
			return { Accordion: this.Accordion }
		},
		components: {
			ACrmContainer
		},
		data: () => ({
			search: '',
			items: [
				{title: "Як сплатити тур?", child: [
					{title: 'Умови та правила сплати послуг через LiqPay', content: [
						{ block: ['Для здійснення купівлі послуг на нашому сайті за допомогою платіжної системи LiqPay, Вам необхідно виконати наступні дії: Вибрати туристичні послуги або туристичний пакет та внести необхідні данні. Для сплати послуг - перейти на сторінку платіжної системи LiqPAY і вибрати спосіб платежу: Приват 24 або карти Mastercard чи VISA. Дотримуючись інструкції сервісу зробити платіж. Після отримання позначки «сплачено» в особистому кабінеті системи LiqPAY , приблизно через 30 хвилин отримати ваучери на всі замовлені послуги та квитки на авіаперевезення в особистому кабінеті ТОВ «Туристичний клуб».']},
						{ block: [
							'Для здійснення сплати за послуги замовленого динамічного тарифу у Вас є 20 хвилин. Після цього терміну ціна може змінюватись.',
							'Якщо ви робите бронювання туру яке потребує підтвердження, Ваші кошти будуть заблоковані системою та списані тільки після підтвердження послуг.'
						]},
						{ block: [
							'Якщо замовлення не підтверджується, заблоковані кошти будуть розблоковані та доступні через декілька хвилин після повідомлення про неможливість підтвердження послуг.',
							'Управляти захищеним платежем Ви можете в Персональному кабінеті LiqPay відразу після сплати.',
							'До сплати приймаються карти Mastercard та VISA випущені в Україні.'
						]},
						{ block: [
							'Вартість замовлених послуг сплачується шляхом 100 % передплати за допомогою платіжної системи LiqPay, доступ до якої забезпечено на Сайті www.BravoTravel.ua і відповідно до правил платіжних систем. Вартість замовлених Послуг вважається сплаченою, якщо платіжною системою надана інформація про списання коштів з рахунку замовника послуг на користь Туроператора. Сплата замовлених послуг за допомогою української платіжної системи LiqPay за тури розміщенні на сайті відбувається без додаткової комісії. В призначенні платежу обов’язково треба вказувати № замовлення або рахунку. Послуги системи LiqPay на сайті www.tcc.a забезпечує найбільший провайдер онлайн платежів в Україні - Приватбанк.'
						]},
						{ block: [
							'Всі реквізити вводяться на захищеній сторінці банка, який використовує технологію захисту та повну безпеку операцій. ТОВ «Туристичний клуб» отримує лише повідомлення про здійснення платежу. Захищений платіж – це гарантія безпечного розрахунку за послуги. Безпека системи LiqPay реалізується технологією OTP, а також технологією 3D secure cоde.'
						]},
						{ block: [
							'Операції підтверджуються динамічним одноразовим паролем, який надсилається в SMS-повідомленні. Сервіс має сертифікати GoDaddy Secure Web Site, Verified by Visa та MasterCard SecureCode.'
						]}
					]},
					{title: 'Умови та правила сплати послуг через будь-який банк', content: [
						{ block: [
							'Тут будет какой то текст'
						]}
					]},
					{title: 'Сплата частинами', content: [
						{ block: [
							'Тут будет какой то текст'
						]}
					]},
				]},
				{title: "Лист на повернення", content: [
					{ block: [
						`Лист на повернення коштів - <a href=''>Завантажити</a>`
					]}
				]},
				{title: "Договір з туристом", content: [
					{ block: [
						'Тут будет какой то текст'
					]}
				]}
			],
			content: null,
			tree: true,
			Accordion: {
				count: 0,
				active: null
			}
		}),
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-crm {
		&-list {
			padding-left: 20px;
			&-item {
				margin-bottom: 10px;
				&:not(.child) {
					list-style-type: disc;
					color: rgba($cyprus, .25);
				}
			}
			&-link,
			.app-accordion__title .heading-4 {
				font-size: 14px;
				font-weight: 400;
				display: inline;
				cursor: pointer;
				color: rgba($black, .8);
				background-image: linear-gradient(to right, $cyprus 75%, transparent 75%);
				background-position: left bottom;
				background-repeat: repeat-x;
				background-size: 5px 1px;
				transition: color $transition;
				@include minw( $grid-breakpoints-xs ) {
					font-size: 16px;
				}
				&:hover,
				&.active {
					color: $green-persian;
					background-image: linear-gradient(to right, $green-persian 75%, transparent 75%);
				}
			}
			&-child {
				padding-top: 10px;
			}
			.app-accordion__title {
				padding: 0;
				.heading-4 {
					.app-icon {
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						right: calc(100% + 10px);
					}
				}
			}
			.app-accordion__content {
				padding: 10px 0 5px 0px;
				border-top: none;
				@include minw( $grid-breakpoints-xs ) {
					padding-left: 25px;
				}
			}
		}
		&-content-info {
			margin-top: 30px;
			@include minw( $grid-breakpoints-xs ) {
				margin-top: 50px;
			}
			.a-sub-heading {
				margin-bottom: 15px;
				@include maxw( $grid-breakpoints-xs - 1 ) {
					font-size: 16px;
				}
			}
		}
	}
</style>
